.responsive-container {
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.4);
  margin: auto;
  margin-top: 10%;
  height: 450px;
  max-width: 500px;
}
.registration-container {
  box-shadow: 0 0 12px 4px rgba(179, 178, 178, 0.4);
  padding: 1%;
  margin-top: 2%;
}

.uploader {
  box-shadow: 0 0 12px 4px rgba(179, 178, 178, 0.4);
  padding: 20px;
  margin-top: 10px;
}

.name-form {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (max-width: 1600px) {
  .registration-container {
    max-width: 96%;
    padding: 2%;
  }
}
@media (max-width: 768px) {
  /* Adjust the width as needed */
  .responsive-container {
    height: 300px;
    margin-top: 50%;
    max-width: 90%;
    height: 400px;
  }
  .name-form {
    flex-direction: column;
  }
  .registration-container {
    max-width: 96%;
    padding: 2%;
  }
}
