ul li .select {
  display: block;
  position: absolute;
  border: 5px solid #aaaaaa;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  top: 20px;
  left: 20px;
  z-index: 100;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}
ul li .select {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  background-color: white;
  height: 25px;
  width: 25px;
  top: -1px;
  left: -2px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}
.checked::before {
  /* background: var(--primary-color); */
  background: #000000;
}
.my-input {
  display: block;
  width: 50%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.my-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.txt {
  color: black;
}
.typeanimation {
  align-self: center;
  height: 100%;
  background-color: red;
}

.user {
  /* background-color: #3a3a3a !important; */
  color: red;
  /* border: 5px solid blue !important; */
}
.correct {
  /* color: green; */
  color: blue;
}
