.container {
  width: 80%;
  margin: auto;
}

.row {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.col-md-12 {
  width: 100%;
  margin: auto;
}

.card {
  width: 100%;
  margin: auto;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-body {
  padding: 1.25rem; /* Bootstrap's default padding for card body */
}

.mb-3 {
  margin-bottom: 1rem; /* Bootstrap's default margin for mb-3 */
}

.float-end {
  float: right;
}

.form-control {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  box-sizing: border-box;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #007bff;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s,
    box-shadow 0.15s;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
