@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --primary-color: #0dff92;
  --dark-color: #222222;
  --light-color: #f0f0f0;
}
* {
  ::-webkit-scrollbar {
    width: 5px;
    height: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(11, 128, 129, 1);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
  }
}

body,
html {
  height: 100%;
  /* background: var(--dark-color); */
  background: rgb(240, 240, 240);
  overflow: hidden;
}

* > * {
  font-family: "Poppins", sans-serif;
}

.container {
  display: block;
  position: relative;
  margin: 40px auto;
  height: auto;
  width: 800px;
  padding: 20px;
}

.container .title {
  font-size: 3em;
  text-align: center;
  /* border: 5px solid var(--primary-color); */
  padding: 0.3em 0.2em;
  border-radius: 4px;
}

.text-light {
  color: var(--light-color);
}

.container ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.container .questions {
  padding: 1em;
  /* height: 500px; */
}

/* 
.container .questions .qid{
  padding: .2em .7em;
  color: #222222;
  background-color: #0DFF92;
  border-radius: 50px;
} */

.container .grid {
  margin-top: 3em;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.container .btn {
  padding: 0.2em 1.7em;
  border: none;
  border-radius: 0.1em;
  font-size: 1.2em;
}

.container .btn:hover {
  cursor: pointer;
  background-color: #f5f5f5;
  color: #202020;
}

.next {
  background-color: rgb(0, 132, 255);
  justify-self: flex-end;
}

.prev {
  background-color: rgb(0, 132, 255);
  justify-self: flex-start;
}

ul li {
  color: #aaaaaa;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  /* height: 50px; */
  /* border-bottom: 1px solid #333; */
}

ul li input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

ul li label {
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 1.3em;
  padding: 20px 25px 25px 80px;
  /* margin: 10px auto; */
  /* height: 25px; */
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}

ul li:hover label {
  color: #474545;
}

ul li .check {
  display: block;
  position: absolute;
  border: 5px solid #aaaaaa;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  top: 20px;
  left: 20px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

ul li:hover .checked {
  border: 5px solid #ffffff;
}

ul li .check::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  background-color: white;
  height: 25px;
  width: 25px;
  top: -1px;
  left: -2px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type="radio"]:checked ~ .check {
  border: 5px solid #3a3a3a;
}

input[type="radio"]:checked ~ .check::before {
  background: var(--primary-color);
  /* background: #3a3a3a; */
}

input[type="radio"]:checked ~ .text-primary {
  background: #000000;
}

/* To get selected option we are using this checked class */
.checked {
  background-color: #3a3a3a !important;
  color: red;
  border: 5px solid var(--primary-color) !important;
}

.checked::before {
  /* background: var(--primary-color); */
  background: #000000;
}
.my-input {
  display: block;
  width: 50%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.my-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.txt {
  color: black;
}
.typeanimation {
  align-self: center;
  height: 100%;
  background-color: red;
}

.user {
  /* background-color: #3a3a3a !important; */
  color: red;
  background-color: rgb(236, 126, 126);
  /* border: 5px solid blue !important; */
}
.correct {
  /* color: green; */
  color: blue;
  /* margin-left: 20px; */
  background-color: rgb(95, 247, 95);
}

.menus:hover {
  background: #8f8e8e;
}

.active {
  color: red;
  background: #8f8e8e;
}

/* .cheating-btn {
  background-color: red;
  color: white;
} */
